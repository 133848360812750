import React from 'react'
import Head from 'next/head'
import { Link, PageFooter } from '#hh/client/components'
import { defaultLocale, messages as translations } from '#hh/shared/intl'

// TODO need app router to translate 404

function NotFoundPage() {

	const messages = translations[defaultLocale]
	const title = messages.page_not_found_title + ' - ' + 'Headline Hunter'

	return (
		<div className="view view--page">
			<Head>
				<title>{title}</title>
			</Head>
			<main className="viewMain">
				<div className="floatyBox">
					<h1 className="floatyBox__title">404</h1>
					<p className="floatyBox__text">{messages.page_not_found_message}</p>
					<Link
						className="button floatyBox__action icon icon:arrow_forward"
						href="/"
					>
						{messages.page_not_found_back_label}
					</Link>
				</div>
			</main>
			<PageFooter />
		</div>
	)
}

export default NotFoundPage


// export default composePage({
// 	public: true,
// })(NotFoundPage)